import { VITE_ENVIRONMENT } from 'src/config/env';
import { OrString } from 'src/interface/utility';

const getHostname = () => {
  // Document is not available in the service worker, we need to use self.
  if (typeof document === 'undefined' && typeof self !== 'undefined') {
    return self.location.hostname;
  }

  return document.location.hostname;
};

// used in tests
// ts-unused-exports:disable-next-line
export const pickEnv = ({
  hostname,
  requestedEnvironment,
}: {
  hostname: string;
  requestedEnvironment?: string;
}) => {
  if (hostname === 'localhost') {
    return requestedEnvironment;
  }

  const hostnameParts = hostname.split('.');
  if (hostnameParts.length > 2) {
    const [env, domain] = hostnameParts.slice(-3, -1);
    if (domain === 'motional') {
      return env;
    }
  } else if (hostnameParts.length === 2 && hostnameParts[0] === 'motional') {
    return 'prd';
  }

  throw new Error(
    'Attempting to access the Motional command center through an invalid URL',
  );
};

export type Env = 'com' | 'prd' | 'pre-prd' | 'hil' | 'stg';

// <env | 'prd'>.motional.cc
export const env = pickEnv({
  hostname: getHostname(),
  requestedEnvironment: VITE_ENVIRONMENT ?? 'PLEASE_SET',
}) as OrString<Env>;

export const vehicleStatus =
  `https://vehicle-status.${env}.motional.cc` as const;
export const surveillance = `https://surveillance.${env}.motional.cc` as const;
export const user = `https://user-profile.${env}.motional.cc` as const;
export const notifications = `https://wuphf.${env}.motional.cc` as const;
export const registrar = `https://registrar.${env}.motional.cc` as const;
export const booking = `https://booking.${env}.motional.cc` as const;
export const armada = `https://armada.${env}.motional.cc` as const;
export const nursery = `https://nursery.${env}.motional.cc` as const;
export const kamaji = `https://kamaji.${env}.motional.cc` as const;
export const vehicleScheduling =
  `https://vehicle-scheduling.${env}.motional.cc` as const;
export const dataColosseum =
  `https://data-colosseum.${env}.motional.cc` as const;
export const geppetto = `https://geppetto.${env}.motional.cc` as const;
export const dora = `https://dora.${env}.motional.cc` as const;
export const deploymentServer = `https://registrar.${env}.motional.cc` as const;
export const apiServer = `https://api.${env}.motional.cc` as const;
export const concierge = `https://concierge.${env}.motional.cc` as const;
export const cerebro = `https://cerebro.${env}.motional.cc` as const;
export const webPush = `https://web-push.${env}.motional.cc` as const;
export const rvaTracker = `https://rva-tracker.${env}.motional.cc` as const;

// No longer in use
// export const simcar = `wss://muletto.${env}.motional.cc`;
// export const notifier = `wss://notifier.${env}.motional.cc`;
// export const scribe = `https://scribe.${env}.motional.cc`;
// export const rca = `https://intercom.${env}.motional.cc`;
// export const map = `https://editor.maps.motional.com/api/v2`;
